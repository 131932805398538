<template>
  <div>
    <div
      class="text-center d-flex justify-center align-center"
      style="height: 80vh;"
      v-if="isLoading"
    >
      <div>
        <v-progress-circular
          class="text-center"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-card v-else>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <h1 class="mb-3">Пользователь {{detail.role}}</h1>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Имя"
                    v-model="detail.username"
                  ></v-text-field>
                  <v-text-field
                    label="Почта"
                    v-model="detail.email"
                    :error-messages="checkErrors('detail.email')"
                  ></v-text-field>
                  <PhoneList
                    v-model="detail.userPhones"
                  />
                  <DatePicker
                    v-model="detail.birth_date"
                    label="Дата рождения"
                  />
                </v-col>
                <v-col cols="6">
                  <PasswordChange/>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="detail.description"
                    label="Доп информация"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <div class="text-right">
          <v-btn
            class="primary mr-2"
            small
            :loading="isSaving"
            @click="handleSave"
          > Сохранить
          </v-btn>
          <v-btn
            class="mr-2"
            small
          > Отмена
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import { minLength, required, email } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import DatePicker from '@/components/views/admin/common/DatePicker'
import PhoneList from '@/components/views/admin/user/Detail/PhoneList'
import PasswordChange from '@/components/views/admin/profile/PasswordChange'

export default {
  mixins: [formatters, validationErrors],
  components: { PasswordChange, PhoneList, DatePicker },
  name: 'Profile',
  data () {
    return {
      detail: this.$store.getters['auth/getProfile'],
      isSaving: false
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      email: {
        email,
        isUnique (value) {
          let result = true
          if (this.$v.detail.email.$dirty && value) {
            result = this.$store.dispatch('auth/isUnique', {
              data: {
                email: value,
                id: this.detail.id
              }
            })
          }
          return result
        }
      },
      userPhones: {
        $each: {
          phone: {
            phone: {
              required,
              minLength: minLength(11)
            }
          }
        }
      }
    }
  },
  computed: {
    isLoading () {
      return !this.$store.getters['auth/getProfile']
    }
  },
  watch: {
    isLoading () {
      this.detail = this.$store.getters['auth/getProfile']
    }/*,
    detail: {
      handler () {
        this.$store.commit('auth/fillProfile', this.detail)
      },
      deep: true
    }*/
  },
  methods: {
    async handleSave () {
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.detail.$touch()
        await this.checkPending()
        if (!this.$v.detail.$anyError) {
          await this.$store.dispatch('auth/save', {
            data: this.detail
          })
        }
        this.isSaving = false
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
